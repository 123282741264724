export default {
  name: "CifPortofolio",
  data() {
    return {
      property: {
        animation: {
          cifPortofolio: {
            isLoading: false,
          },
          isDownloadingFile: false,
        },
        listElement: {
          cifPortofolio: {
            downloading: false,
            message: "",
          },
        },
      },
      dataForm: {
        cifNumber: "",
        namaNasabah: "",
        noIdentitas: "",
        motherName: "",
        cifIdAddress: "",
      },
      table: {
        data: {
          cifPortofolio: [],
        },
      },
    };
  },
  methods: {
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        namaNasabah: this.dataForm.namaNasabah,
        motherName: this.dataForm.motherName,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/portfolio/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Laporan_Portofolio_Nasabah.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async getReportCifPortofolio() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.cifPortofolio.isLoading = true;
      this.property.listElement.cifPortofolio.downloading = true;
      this.table.data.cifPortofolio = [];
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/portfolio",
          payload: {
            namaNasabah: this.dataForm.namaNasabah,
            motherName: this.dataForm.motherName,
          },
        });
        if (resp.data.code === "SUCCESS") {
          setTimeout(() => {
            this.table.data.cifPortofolio = resp.data.data;
          }, 1000);
        } else {
          this.property.listElement.cifPortofolio.message = resp.data.message;
        }
      } catch (error) {
        this.table.data.cifPortofolio = [];
        this.property.listElement.cifPortofolio.message = error.response
          ? `Gagal Mengunduh Data : ${error.response.statusText}`
          : "Terjadi Kesalahan";
      } finally {
        setTimeout(() => {
          this.property.animation.cifPortofolio.isLoading = false;
          this.property.listElement.cifPortofolio.downloading = false;
        }, 1000);
      }
    },
  },
  mounted() {
    this.property.listElement.cifPortofolio.message = "DATA NOT FOUND";
    // this.getReportCifPortofolio();
  },
};
